import {State} from '../types'

export const getInvoice = (state: State) => state.invoice.invoice

export const getCouponError = (state: State) => state.invoice.couponError

export const getGiftCardError = (state: State) => state.invoice.giftCardError

export const getGiftCardPaymentDetails = (state: State) => state.invoice.giftCardPaymentDetails

export const getCouponTicketId = (state: State) => state.invoice.coupon.ticketId

export const couponSubmitted = (state: State) => state.invoice.coupon.submitted

export const getCouponCode = (state: State) => (couponSubmitted(state) ? state.invoice.coupon.code : '')

export const isValidatingCoupon = (state: State) => state.invoice.coupon.validating

export const isEditingCoupon = (state: State) =>
  getCouponCode(state)?.length > 0 && !couponSubmitted(state) && !getCouponError(state)

export const giftCardSubmitted = (state: State) => state.invoice.giftCard.submitted

export const getGiftCardCode = (state: State) => (giftCardSubmitted(state) ? state.invoice.giftCard.code : '')

export const isValidatingGiftCard = (state: State) => state.invoice.giftCard.validating

export const isEditingGiftCard = (state: State) =>
  getGiftCardCode(state)?.length > 0 && !giftCardSubmitted(state) && !getGiftCardError(state)
