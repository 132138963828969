import {VisitorType} from '@wix/events-types'
import {type RichContent, isRichContentEmpty} from '@wix/ricos'
import {
  hasGuestsGoing as commonsHasGuestsGoing,
  getAboutText,
  getRestrictedTo,
  hasSeatingPlan,
  isEventLocationOnline,
  isLocationTbd,
  isRegistrationClosed,
  isRegistrationClosedVisible,
  isRegistrationScheduled,
  isRsvp,
  isTicketed,
  isWithoutRegistration,
  ticketedOrInitiallyTicketed,
} from '@wix/wix-events-commons-statics'
import {isEditor} from '../../../../commons/selectors/environment'
import {State, ThankYouMessageState} from '../types'
import {isMemberRsvpExists} from './member-rsvp'
import {areSelectedDonationsValid, getSelectedTicketsQuantity} from './selected-tickets'
import {getTickets, hasTicketsOnSale} from './tickets'

const DEFAULT_GUEST_COUNT = 3

export const getEvent = (state: State) => state.event.event

export const getEventRichContent = (state: State) => state.event.richContent as unknown as RichContent

export const getDefaultGuestTotal = (rsvpEvent: boolean): TotalEventGuests =>
  rsvpEvent ? {YES: DEFAULT_GUEST_COUNT} : DEFAULT_GUEST_COUNT

export const getFormMessages = (event: wix.events.Event, state: ThankYouMessageState) => {
  const {negativeMessages, waitlistMessages, positiveMessages} = event.form.messages.rsvp

  switch (state) {
    case 'no':
      return negativeMessages
    case 'waiting':
      return waitlistMessages
    default:
      return positiveMessages
  }
}

export const hasGuestsGoing = (state: State) => {
  const event = getEvent(state)
  const totalGuests = event?.totalGuests ?? 0
  const guestsGoing = commonsHasGuestsGoing(totalGuests, isRsvp(event))

  if (typeof totalGuests === 'object') {
    return Object.keys(totalGuests)?.length > 0 && guestsGoing
  }

  return Boolean(totalGuests) && guestsGoing
}

export const getTotalGuests = (state: State): TotalEventGuests => {
  const event = getEvent(state)
  const rsvpEvent = isRsvp(event)
  const areGuestsGoing = hasGuestsGoing(state)

  if (!areGuestsGoing && isEditor(state)) {
    return getDefaultGuestTotal(rsvpEvent)
  }

  return event.totalGuests
}

export const getMembers = (state: State): Member[] => getEvent(state).members || []

export const isAboutSectionVisible = (state: State) => {
  const richContent = getEventRichContent(state)

  return isEditor(state) || !!getAboutText(getEvent(state)) || !!(richContent && !isRichContentEmpty(richContent))
}

export const isMapVisible = (state: State) => !isLocationTbd(getEvent(state)) && !isEventLocationOnline(getEvent(state))

export const isSecondRegistrationButtonVisible = (state: State, rsvpButtonContentUpdatesEnabled: boolean) => {
  const event = getEvent(state)
  const registrationClosed = isRegistrationClosed(event)
  const ticketedEvent = rsvpButtonContentUpdatesEnabled ? ticketedOrInitiallyTicketed(event) : isTicketed(event)
  const withoutRegistration = isWithoutRegistration(event)
  const memberRsvpExists = isMemberRsvpExists(state)
  const registrationScheduled = isRegistrationScheduled(event)

  return !(registrationClosed || memberRsvpExists || ticketedEvent || withoutRegistration || registrationScheduled)
}

export const isDemoEvent = (state: State) => Boolean(Object.keys(state.demoEvents).length)

export const isCheckoutButtonDisabled = (state: State) => {
  const event = getEvent(state)

  return hasSeatingPlan(event)
    ? isRegistrationClosedVisible(event) || !hasTicketsOnSale(getTickets(state))
    : isRegistrationClosedVisible(event) || !getSelectedTicketsQuantity(state) || !areSelectedDonationsValid(state)
}

export const isRestrictedTo = (state: State, visitorType: VisitorType) =>
  getRestrictedTo(getEvent(state)) === visitorType
